import {ecomAppDefID, EDITOR_SCRIPT_DSN, Events, SPECS, STORES_PREMIUM, wishlistPageId} from './constants';
import {Experiments} from '../common/experiments/Experiments';
import {createStoreFrontBILogger} from '@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger';
import {isMembersAreaInstalled, maybeInstallMembersArea, withMembersArea} from '@wix/members-area-integration-kit';
import {Logger} from '@wix/bi-logger-ec-sf';
import {getAppManifest} from './services/appManifest';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {DependantApps} from './services/DependantApps';
import {StyleParams} from './services/StyleParams';
import {sliderWidthMigration} from './migration-scripts/sliderMigration';
import {adiMissingPagesMigration} from './migration-scripts/adiMissingPagesMigration';
import {translateFunctionFactory} from '../common/translations/translations';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';
import {delay} from '@wix/wixstores-client-core/dist/src/utils/delay';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {reinstallPage} from './migration-scripts/reinstallPage';
import {
  setSentryInstance,
  withErrorReportingWrapping,
  wrapAsyncFunctionsWithPromise,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {freeProductsLegacyFlag} from './migration-scripts/freeProductsLegacyFlag';
import {productWithSubscriptionSaved} from './migration-scripts/productWithSubscriptionSaved';
import {addMembersArea, addPage, addWidget} from './services/publicApi';
import {handleOnEvent} from './services/onEvent';
import {doTransaction} from './transaction';

let appToken;
let options: IEditorOptions;
let t;
let locale: string = 'en';
let biLogger: Logger;
let sdk: IEditorSdk;
let dependantApps: DependantApps;
let styleParams: StyleParams;
let storeId: string;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let experiments: Experiments;
let hasStoresPremium;

async function getExperiments(instance: string): Promise<Experiments> {
  const experimentsResponse = await fetch('/_api/wix-ecommerce-storefront-web/api', {
    method: 'post',
    body: JSON.stringify({
      query: require('!raw-loader!../common/experiments/getConfig.graphql'),
      source: 'WixStoresWebClient',
      operationName: 'getConfig',
    }),
    headers: {
      Authorization: instance,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then((data) => data.json())
    .then((data) => {
      hasStoresPremium = data?.data?.premiumFeatures?.some((feature) => feature.name === STORES_PREMIUM);
      return (data.data.experiments || []).reduce((acc, e) => {
        acc[e.name] = e.value;
        return acc;
      }, {});
    });
  return new Experiments(experimentsResponse);
}

function createSentryInstance(monitoring) {
  const configuration = {
    dataCallback: (data) => {
      data.environment = 'Worker';
      return data;
    },
  };
  return monitoring.createSentryMonitorForApp(EDITOR_SCRIPT_DSN, configuration);
}

function getAssetsUrl() {
  const version = getAppVersion();
  return `https://static.parastorage.com/services/wixstores-client-worker/${version}/assets`;
}

function isMembersPage(tpaPageId) {
  return tpaPageId === PageMap.ORDER_HISTORY || tpaPageId === PageMap.WISHLIST;
}

async function addStoresPagesAsPanel() {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId(appToken, ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll();
  const storesPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  return Promise.all(
    storesPages.map(async (page) => {
      const pageRef = {id: page.id, type: page.type};
      const pageData = await sdk.pages.data.get(appToken, {
        pageRef,
      });
      if (!pageData.managingAppDefId && !isMembersPage(pageData.tpaPageId)) {
        await sdk.pages.data.update(appToken, {
          pageRef,
          data: {managingAppDefId: ecomAppDefID},
        });
        return pageData;
      }
    })
  );
}

async function setStateForPages() {
  const applicationPages = await sdk.document.pages.getApplicationPages(appToken);
  applicationPages
    .filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID)
    .forEach((pageData) => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.CART ||
        pageData.tpaPageId === PageMap.THANKYOU
      ) {
        sdk.document.pages.setState(appToken, {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    });
}

const showProgressBar = (shouldInstallMembers: boolean = true): Promise<Function> => {
  let currStep = 1;
  const baseTranslationKey = shouldInstallMembers
    ? 'settings.productPage.loadingModal.addingMembersArea'
    : 'settings.productPage.loadingModal.addingWishlist';
  const progressBarTitle = t(baseTranslationKey);
  const progressBarSubTitles = [
    t(`${baseTranslationKey}.firstSubtitle`),
    t(`${baseTranslationKey}.secondSubtitle`),
    t(`${baseTranslationKey}.thirdSubtitle`),
  ];
  const timeToWaitBetweenSteps = shouldInstallMembers ? 6000 : 3500;

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(async (resolve) => {
    const panelRef = await sdk.editor.openProgressBar(appToken, {
      title: progressBarTitle,
      totalSteps: 3,
      currentStep: currStep,
      stepTitle: progressBarSubTitles[0],
      image: `${getAssetsUrl()}/images/wishlist.svg`,
    });
    await delay(timeToWaitBetweenSteps);
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: ++currStep,
      stepTitle: progressBarSubTitles[1],
    });
    await delay(timeToWaitBetweenSteps);
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: ++currStep,
      stepTitle: progressBarSubTitles[2],
    });
    await delay(500);
    resolve(() => {
      sdk.editor.closeProgressBar(appToken, false, {panelRef});
    });
  });
};

export async function navigateToWishlist() {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  biLogger.clickToPreviewWishlistInMembersSfe({});
  const wishlistPageRef = await dependantApps.getWishlistPageRef();
  sdk.document.pages.navigateTo(appToken, {pageRef: wishlistPageRef});
}

function createAppApi(): AppApiModel {
  return {
    isMembersInstalled: () => isMembersAreaInstalled(),
    installMembersAreaAndWishlistPage: (openerCompRef: string) => {
      return doTransaction(
        sdk,
        async () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-init'});
          const progressBarPromise = showProgressBar(true);

          await maybeInstallMembersArea();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-after-members-install'});
          await dependantApps.tryInstallWishlist();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-after-wishlist-install'});

          const close = await progressBarPromise;
          await delay(500);
          close();
          await delay(500);

          await enableProductPageWishlist(openerCompRef);

          sdk.editor
            .showUserActionNotification('', {
              message: t('settings.productPage.floatingNotification.membersAreaAdded'),
              type: 'success',
              link: {caption: t('settings.productPage.floatingNotification.previewWishlist')},
            })
            .then((linkClicked) => {
              linkClicked && navigateToWishlist();
            });
        },
        experiments
      );
    },
    installWishlist: async (openerCompRef: string) => {
      return doTransaction(
        sdk,
        async () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          biLogger.wishlistInstallationStage({stage: 'installWishlist-init'});

          const progressBarPromise = showProgressBar(false);

          await dependantApps.tryInstallWishlist();

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          biLogger.wishlistInstallationStage({stage: 'installWishlist-after-wishlist-install'});

          const close = await progressBarPromise;
          await delay(500);
          close();
          await delay(500);

          await enableProductPageWishlist(openerCompRef);

          sdk.editor
            .showUserActionNotification('', {
              message: t('settings.productPage.floatingNotification.wishlistAdded'),
              type: 'success',
              link: {caption: t('settings.productPage.floatingNotification.previewWishlist')},
            })
            .then((linkClicked) => {
              linkClicked && navigateToWishlist();
            });
        },
        experiments
      );
    },
    uninstallWishlist: () => {
      return doTransaction(sdk, () => dependantApps.uninstallWishlistPageInMembersArea(), experiments);
    },
    isAppInstalled: async (appDefinitionId: string) => {
      return dependantApps.isAppInstalled(appDefinitionId);
    },
    setCheckoutStyleParam(type: string, key: string, param: {value: any}): Promise<void> {
      return doTransaction(sdk, () => styleParams.setCheckoutStyleParam(type, key, param), experiments);
    },
  };
}

async function setStoresPages() {
  const shouldUseLightboxes = experiments.enabled(SPECS.UseLightboxes);
  await setStateForPages();
  await dependantApps.addCheckoutIfNeeded();
  shouldUseLightboxes && (await dependantApps.addLighboxes(options.initialAppData.applicationId));
}

function registerEvents() {
  sdk.document.application.registerToCustomEvents('', {eventTypes: [Events.dashboardClosed]});
}

async function editorReady(_editorSDK, _appToken, _options) {
  options = _options;
  appToken = _appToken;
  sdk = _editorSDK;

  const instance: string = await (sdk as any).document.info.getAppInstance('token');
  experiments = await getExperiments(instance);

  setSentryInstance(createSentryInstance(options.monitoring));
  wrapAsyncFunctionsWithPromise(true);
  registerEvents();

  const encodedInstance = instance.substring(instance.indexOf('.') + 1);
  const parsedInstance = JSON.parse(atob(encodedInstance));
  storeId = parsedInstance.instanceId;
  const isMerchant = true;
  biLogger = createStoreFrontBILogger({uuid: parsedInstance.uid}, parsedInstance.biToken, {
    storeId,
    isMerchant,
    appName: 'wixstores worker',
  });

  dependantApps = new DependantApps(sdk, appToken, biLogger, instance, options.initialAppData.metaSiteId);
  styleParams = new StyleParams(sdk, options.initialAppData.applicationId);
  locale = await sdk.editor.environment.getLocale();
  t = await translateFunctionFactory(locale);
  await doTransaction(
    sdk,
    async () => {
      if (!experiments.enabled(SPECS.EcomPlatformInstallation)) {
        await addMembersArea(options.origin.type, options.firstInstall, options.biData);
      }
      await dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded({biData: options.biData});

      const storesPages = await addStoresPagesAsPanel();
      if (storesPages.length) {
        await setStoresPages();
      }
    },
    experiments
  );
  sdk.editor.setAppAPI(appToken, withErrorReportingWrapping(createAppApi()));
}

const onEvent = (data) => {
  const {eventType, eventPayload} = data;

  handleOnEvent(sdk, eventType, eventPayload, dependantApps, experiments);
};

async function enableProductPageWishlist(productPageCompRef: string) {
  await sdk.tpa.setStyleParams(appToken, {
    compRef: {id: productPageCompRef, type: 'DESKTOP'},
    styleParams: [{type: 'boolean', key: 'productPage_wishlistEnabled', param: {value: true}}],
  });
}

function handleAction({type, payload}) {
  // eslint-disable-next-line @typescript-eslint/tslint/config
  try {
    if (type === 'migrate') {
      switch (payload.type) {
        case 'sliderWidth':
          return sliderWidthMigration(sdk, appToken);
        case 'adiMissingPages':
          return adiMissingPagesMigration(sdk, appToken, storeId, biLogger);
        case 'reinstallWishlist':
          return reinstallPage(
            sdk,
            appToken,
            wishlistPageId,
            dependantApps.installWishlistPageInMembersArea.bind(dependantApps)
          );
        case 'reinstallCheckout':
          return reinstallPage(sdk, appToken, 'checkout', dependantApps.addCheckoutIfNeeded.bind(dependantApps));
        case 'tryInstallWishlistTPA':
          return dependantApps.installWishlistTPA();
        case 'freeProductsLegacyFlag':
          return freeProductsLegacyFlag(sdk, appToken, payload.value);
        case 'productWithSubscriptionSaved':
          return productWithSubscriptionSaved();
        default:
          return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

const getControllerPresets = () => Promise.resolve([]);

const publicApi = {
  addPage: (pageId: string, shouldNavigate = false) => addPage(sdk, pageId, shouldNavigate),
  addWidget: (widgetId: string, addToAllPages: boolean) => addWidget(sdk, widgetId, addToAllPages),
  addMembersArea: (editorType: string, firstInstall: boolean, biData?: {origin?: string}) =>
    addMembersArea(editorType, firstInstall, biData),
};

export const editorScript = withMembersArea(
  withErrorReportingWrapping({
    editorReady,
    handleAction,
    getAppManifest: () => getAppManifest(t, locale, appToken, hasStoresPremium),
    onEvent,
    getControllerPresets,
    exports: publicApi,
  }),
  {installAutomatically: false}
);
